import { createSlice } from "@reduxjs/toolkit";

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: { loaded: false, data: [] },
  reducers: {
    setTickets: (state, action) => ({ loaded: true, data: action.payload }),
  },
});

const { actions, reducer } = ticketsSlice;
const { setTickets } = actions;

export const fetchTickets = () => async (dispatch) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/tickets/`, {
    headers: {
      Authorization: "Token 6410d0671f54e7063925ff287efc838989191f9b",
    },
  });
  const tickets = await response.json();
  dispatch(setTickets(tickets));
};

export default reducer;
