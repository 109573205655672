import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadTicket } from "redux/modules/tickets/ticket";
import { Container, Table } from "react-bootstrap";
import useRegion1 from "components/hooks/useRegion1";
import useProjects from "components/hooks/useProjects";

const TicketDetail = () => {
  const dispatch = useDispatch();
  const regions1 = useRegion1();
  const projects = useProjects();
  const { ticketId } = useParams();
  const { loaded: ticketLoaded, data: ticket } = useSelector(
    (state) => state.ticket
  );
  const isLoaded = ticketLoaded && parseInt(ticketId) === ticket.id;
  useEffect(() => {
    if (!isLoaded) {
      dispatch(loadTicket(ticketId));
    }
  }, [isLoaded, dispatch, ticketId, ticket]);

  return (
    <Container>
      {!isLoaded ? (
        <div className="text-center">Загрузка</div>
      ) : (
        <>
          <h1 className="mt-5">
            Заявка №{ticket.id} от{" "}
            {new Date(ticket.created).toLocaleDateString("ru-ru")}
          </h1>
          <Table striped className="mt-5">
            <tbody>
              <tr>
                <td>ФИО</td>
                <td>{ticket.data.fio}</td>
              </tr>
              <tr>
                <td>ИИН</td>
                <td>{ticket.data.iin}</td>
              </tr>
              <tr>
                <td>телефон</td>
                <td>{ticket.data.phone}</td>
              </tr>
              <tr>
                <td>создана</td>
                <td>{new Date(ticket.created).toISOString()}</td>
              </tr>
              <tr>
                <td>статус</td>
                <td>{ticket.status}</td>
              </tr>
              <tr>
                <td>регион</td>
                <td>
                  {
                    regions1.find(
                      (region1) => region1.id === ticket.data.region1
                    )?.name
                  }
                </td>
              </tr>
              <tr>
                <td>проект</td>
                <td>
                  {
                    projects.find(
                      (project) => project.id === ticket.data.project
                    )?.name
                  }
                </td>
              </tr>
              <tr>
                <td>Описание ошибки</td>
                <td>{ticket.data.error_description}</td>
              </tr>
            </tbody>
          </Table>
          <pre>{JSON.stringify(ticket, null, 2)}</pre>
        </>
      )}
    </Container>
  );
};

export default TicketDetail;
