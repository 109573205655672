import { Container, Nav } from "react-bootstrap";
import { NavLink, Route, Switch, useRouteMatch } from "react-router-dom";
import styles from "./Forms.module.sass";
import Populace from "./Populace";

const Forms = () => {
  const match = useRouteMatch();

  return (
    <Container>
      <h1 className="mt-5 text-center">Новая заявка</h1>
      <div className="mt-3 row justify-content-center">
        <div className="col-9">
          <Nav variant="pills" className="justify-content-between">
            <Nav.Item>
              <Nav.Link
                as={NavLink}
                to={`${match.path}/populace`}
                className="px-5"
              >
                От населения
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to={`${match.path}/spp`} className="px-5">
                По вопросам СПП
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link as={NavLink} to={`${match.path}/gov`} className="px-5">
                Для гос. органов
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
      </div>

      <div className={styles["forms-wrapper"]}>
        <Switch>
          <Route path={`${match.path}/populace`} component={Populace} />
          <Route path={`${match.path}/spp`}>По вопросам СПП</Route>
          <Route path={`${match.path}/gov`}>Для гос. органов</Route>
        </Switch>
      </div>
    </Container>
  );
};

export default Forms;
