import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchRegions } from "../../redux/modules/directories/regions1";

const useRegion1 = () => {
  const dispatch = useDispatch();
  const { loaded, data: regions1 } = useSelector(
    (state) => state.directories.regions1
  );
  useEffect(() => {
    if (!loaded) {
      dispatch(fetchRegions());
    }
  }, [dispatch, loaded]);
  return regions1;
};

export default useRegion1;
