import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import AudienceSelect from "./pages/forms/AudienceSelect";
import Forms from "./pages/forms/Forms";
import TicketDetail from "./pages/tickets/TicketDetail";
import TicketList from "./pages/tickets/TicketList";
import Header from "./components/Header/Header";

function App() {
  return (
    <>
      <Router>
        <Header />
        <Switch>
          <Route path="/" exact={true}>
            <AudienceSelect />
          </Route>
          <Route path="/forms">
            <Forms />
          </Route>
          <Route path="/tickets" exact={true}>
            <TicketList />
          </Route>
          <Route path="/tickets/:ticketId">
            <TicketDetail />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
