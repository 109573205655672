import { createSlice } from "@reduxjs/toolkit";

const ticketSlice = createSlice({
  name: "ticket",
  initialState: { loaded: false },
  reducers: {
    setTicket: (state, action) => ({ loaded: true, data: action.payload }),
  },
});

const { actions, reducer } = ticketSlice;
const { setTicket } = actions;

export const createTicket = (data, history) => async (dispatch) => {
  const response = await fetch(`${process.env.REACT_APP_API_URL}/tickets/`, {
    method: "POST",
    body: JSON.stringify({ data }),
    headers: {
      Authorization: "Token 6410d0671f54e7063925ff287efc838989191f9b",
      "Content-Type": "application/json",
    },
  });
  const ticket = await response.json();
  dispatch(setTicket(ticket));
  history.push(`/tickets/${ticket.id}`);
};

export const loadTicket = (ticketId) => async (dispatch) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/tickets/${ticketId}/`,
    {
      headers: {
        Authorization: "Token 6410d0671f54e7063925ff287efc838989191f9b",
      },
    }
  );
  const ticket = await response.json();
  dispatch(setTicket(ticket));
};

export default reducer;
