import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchTickets } from "../../redux/modules/tickets/tickets";
import { Container, Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import useProjects from "../../components/hooks/useProjects";

const TicketList = () => {
  const dispatch = useDispatch();
  const projects = useProjects();
  const { loaded: isTicketsLoaded, data: tickets } = useSelector(
    (state) => state.tickets
  );

  useEffect(() => {
    if (!isTicketsLoaded) {
      dispatch(fetchTickets());
    }
  }, [dispatch, isTicketsLoaded]);

  return (
    <Container>
      <Table striped bordered size="sm" className="mt-5">
        <thead>
          <tr className="text-center">
            <th>Номер</th>
            <th>Статус</th>
            <th>дата создания</th>
            <th>Проект</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tickets.map((ticket) => (
            <tr>
              <td className="pl-5">
                <Link
                  to={`/tickets/${ticket.id}`}
                >{`заявка #${ticket.id}`}</Link>
              </td>
              <td>{ticket.status}</td>
              <td className="pl-5">
                {new Date(ticket.created).toLocaleDateString("ru-ru")}
              </td>
              <td>
                {
                  projects.find((project) => project.id === ticket.data.project)
                    ?.name
                }
              </td>
              <td>{ticket.kind}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TicketList;
