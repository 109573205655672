import { createSlice } from "@reduxjs/toolkit";

const projectsSlice = createSlice({
  name: "projects",
  initialState: { loaded: false, data: [] },
  reducers: {
    loadProjects: (state, action) => ({ loaded: true, data: action.payload }),
  },
});

const { actions, reducer } = projectsSlice;

export const { loadProjects } = actions;

export const fetchProjects = () => async (dispatch) => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/directories/projects/`,
    {
      headers: {
        Authorization: "Token 6410d0671f54e7063925ff287efc838989191f9b",
      },
    }
  );
  const projects = await response.json();

  dispatch(loadProjects(projects));
};

export default reducer;
