import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { FormProvider, useForm } from "react-hook-form";
import FormSelect from "components/forms/FormSelect";
import FormInput from "components/forms/FormInput";
import { createTicket } from "redux/modules/tickets/ticket";
import { Link, useHistory } from "react-router-dom";
import useProjects from "components/hooks/useProjects";
import useRegion1 from "components/hooks/useRegion1";
import queryString from "query-string";

const Populace = ({ location }) => {
  const params = queryString.parse(location.search);
  const dispatch = useDispatch();
  const history = useHistory();
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(false);
  const projects = useProjects();
  const regions1 = useRegion1();

  const formMethods = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {
      phone: params.phone || "",
    },
  });
  const iinWatcher = formMethods.watch("iin", "");

  useEffect(() => {
    if (iinWatcher && iinWatcher.length === 12) fetchPrevTickets(iinWatcher);
  }, [iinWatcher]);

  const [prevTickets, setPrevTickets] = useState([]);
  const fetchPrevTickets = async (iin) => {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/tickets/?iin=${iin}`,
      {
        headers: {
          Authorization: "Token 6410d0671f54e7063925ff287efc838989191f9b",
        },
      }
    );
    const prevTickets = await response.json();
    setPrevTickets(prevTickets);
    console.log(formMethods.getValues("fio"));
    if (prevTickets.length && formMethods.getValues("fio") === undefined) {
      formMethods.setValue(
        "fio",
        prevTickets.filter((ticket) => ticket.data.fio)[0]?.data.fio
      );
    }
  };
  const onSubmit = (data) => {
    setIsSubmitButtonDisabled(true);
    dispatch(createTicket(data, history));
  };

  return (
    <Container>
      <FormProvider {...formMethods}>
        <Form onSubmit={formMethods.handleSubmit(onSubmit)}>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              ФИО
            </Form.Label>
            <Col sm={9}>
              <FormInput name="fio" rules={{ required: "обязательное поле" }} />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              ИИН
            </Form.Label>
            <Col sm={9}>
              <FormInput
                name="iin"
                rules={{
                  required: "обязательное поле",
                  pattern: {
                    value: /^\d{12}$/,
                    message: "ИИН должен состоять из 12 цифр",
                  },
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Телефон
            </Form.Label>
            <Col sm={9}>
              <FormInput
                name="phone"
                rules={{
                  required: "обязательное поле",
                }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Уникальный код
            </Form.Label>
            <Col sm={9}>
              <FormInput
                name="code"
                rules={{ required: "обязательное поле" }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Выберите проект
            </Form.Label>
            <Col sm={9}>
              <FormSelect
                name="project"
                options={projects.map((project) => ({
                  value: project.id,
                  label: project.name,
                }))}
                rules={{ required: "обязательное поле" }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Регион
            </Form.Label>
            <Col sm={9}>
              <FormSelect
                name="region1"
                options={regions1.map((region1) => ({
                  value: region1.id,
                  label: region1.name,
                }))}
                rules={{ required: "обязательное поле" }}
              />
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Form.Label column sm={3}>
              Описание ошибки
            </Form.Label>
            <Col sm={9}>
              <FormInput
                as="textarea"
                name="error_description"
                rules={{ required: "обязательное поле" }}
              />
            </Col>
          </Form.Group>
          <Button
            type="submit"
            variant="primary"
            block
            disabled={isSubmitButtonDisabled}
          >
            Создать
          </Button>
        </Form>
      </FormProvider>

      {prevTickets.length ? (
        <>
          <h2 className="mt-5">Предыдушие обращения</h2>
          <Table size="sm" striped className="mt-3">
            <thead>
              <tr>
                <th>id</th>
                <th>Дата</th>
                <th>Проект</th>
              </tr>
            </thead>
            <tbody>
              {prevTickets.map((ticket) => (
                <tr key={ticket.id}>
                  <td>
                    <Link to={`/tickets/${ticket.id}`}>
                      Заявка #{ticket.id}
                    </Link>
                  </td>
                  <td>
                    {new Date(ticket.created).toLocaleDateString("ru-ru")}
                  </td>
                  <td>
                    {
                      projects.find(
                        (project) => project.id === ticket.data.project
                      )?.name
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : null}
    </Container>
  );
};

export default Populace;
