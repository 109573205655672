import { configureStore } from "@reduxjs/toolkit";
import directoriesReducer from "./modules/directories/directoriesReducer";
import ticketReducer from "./modules/tickets/ticket";
import ticketsReducer from "./modules/tickets/tickets";

const store = configureStore({
  reducer: {
    directories: directoriesReducer,
    ticket: ticketReducer,
    tickets: ticketsReducer,
  },
});

export default store;
