import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProjects } from "../../redux/modules/directories/projects";

const useProjects = () => {
  const dispatch = useDispatch();
  const { loaded: projectsLoaded, data: projects } = useSelector(
    (state) => state.directories.projects
  );
  useEffect(() => {
    if (!projectsLoaded) {
      dispatch(fetchProjects());
    }
  }, [dispatch, projectsLoaded]);
  return projects;
};

export default useProjects;
