import { combineReducers } from "@reduxjs/toolkit";
import projects from "./projects";
import regions1 from "./regions1";

const directoriesReducer = combineReducers({
  projects,
  regions1,
});

export default directoriesReducer;
