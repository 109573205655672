import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

const Header = () => (
  <Navbar variant="dark" bg="dark">
    <Container className="justify-content-start">
      <Navbar.Brand as={Link} to="/">
        <h4>CRM</h4>
      </Navbar.Brand>
      <Nav>
        <Nav.Link as={NavLink} to="/forms/populace" className="ml-5">
          Новая заявка
        </Nav.Link>
        <Nav.Link as={NavLink} to="/tickets" className="ml-5">
          Заявки
        </Nav.Link>
      </Nav>
    </Container>
  </Navbar>
);

export default Header;
