import { useController } from "react-hook-form";
import ReactSelect from "react-select";
import { Form } from "react-bootstrap";

const FormSelect = ({ options, ...props }) => {
  const {
    field: { onChange, value, ...other },
    fieldState: { invalid, error },
  } = useController(props);
  return (
    <>
      <ReactSelect
        {...other}
        value={options.filter((item) => item.value === value)}
        onChange={(item) => onChange(item.value)}
        placeholder="выберите"
        options={options}
        className={invalid && "is-invalid"}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </>
  );
};

export default FormSelect;
