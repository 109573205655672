import { useController } from "react-hook-form";
import { Form } from "react-bootstrap";

const FormInput = ({ as, ...props }) => {
  const {
    field,
    fieldState: { invalid, isTouched, error },
  } = useController(props);
  field.value || (field.value = "");
  return (
    <>
      <Form.Control
        {...field}
        as={as}
        isInvalid={invalid}
        isValid={isTouched && !error}
      />
      <Form.Control.Feedback type="invalid">
        {error?.message}
      </Form.Control.Feedback>
    </>
  );
};

export default FormInput;
