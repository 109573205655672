import { Container } from "react-bootstrap";
import styles from "./AudienceSelect.module.sass";
import { Link } from "react-router-dom";

const AudienceSelect = () => {
  return (
    <Container>
      <div className={styles["links"]}>
        <Link to="/forms/populace" className={styles["link"]}>
          Населению
        </Link>
        <Link to="/" className={styles["link"]}>
          По вопросам СПП
        </Link>
        <Link to="/" className={styles["link"]}>
          Для гос органов
        </Link>
      </div>
    </Container>
  );
};

export default AudienceSelect;
